import styled from "styled-components";

export const HeaderWrapper = styled.div`
  height: 64px;
  width: 100%;
`;

export const HeaderInnerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const LogoWrapper = styled.a`
  height: 64px;
  width: 100px;
  display: flex;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }

  svg {
    width: 100%;
  }
`;

export const MenuPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LanguagePanel = styled.div`
  position: relative;
  width: 200px;
  top: 10px;
  z-index: 1;
  height: auto;
  transform: scaleY(${(props) => (props.menuOpen ? "1" : "0")});
  overflow: hidden;
  background-color: white;
  padding: 20px;
  border: ${(props) => (props.menuOpen ? "1px solid #dedede" : "0")};
  opacity: ${(props) => (props.menuOpen ? "1" : "0")};
  transition: transform 0.1s, opacity 0.3s;
`;

export const StyledSelect = styled.select`
  border-radius: 2px;
  border: 1px solid #d2d0d0;
  min-width: 100%;
  padding-right: 25px;
  padding: 0.5rem;
`;

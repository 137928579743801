import React, { useContext } from "react";
import { Box, H5, LanguageContext } from "@eriksdigital/atomic-ui/components";
import { StockInformation } from "../StockInformation";
import { DocumentDownload } from "../DocumentDownload";
import { ProductWrapper, ProductImage, ProductInfo, Span } from "./style";
import { Price } from "../Price";
import { GTMContext } from "@eriksdigital/gs-ga4";

const Product = ({
  imageUrl,
  brand,
  itemLink,
  description,
  erpPartNumber,
  productOverviewUrl,
  englishDescription,
  partNumber,
  position,
  currency,
  price,
  priceRaw,
  stockStatus,
}) => {
  const { erpSystem } = useContext(LanguageContext);
  const { sendSelectItemEvent } = useContext(GTMContext);

  const handleOnClickItem = (e) => {
    e.preventDefault();
    sendSelectItemEvent({
      itemId: partNumber,
      price: priceRaw,
      itemBrand: brand,
      itemStockStatus: stockStatus.available,
      itemName: englishDescription,
      index: position,
      materialNumber: erpPartNumber,
    });
    window.open(itemLink, "_blank");
  };

  return (
    <ProductWrapper data-testid={`product-list-${erpPartNumber}`}>
      <ProductImage src={imageUrl} />
      <ProductInfo>
        <H5
          as="a"
          onClick={handleOnClickItem}
          rel="noopener noreferrer"
          href={itemLink}
        >
          {brand && `${brand} `}
          {description.replace(/&quot;/g, '"')}
        </H5>
        <Span>{erpPartNumber}</Span>
        <StockInformation
          partNumber={erpPartNumber}
          erpSystem={erpSystem}
          stockStatus={stockStatus}
        />
        <DocumentDownload
          partNumber={erpPartNumber}
          documentUrl={productOverviewUrl}
        />
      </ProductInfo>
      <Box direction="row" alignItems="center" justifyContent="end">
        <Price
          erpPartNumber={erpPartNumber}
          partNumber={partNumber}
          englishDescription={englishDescription}
          brand={brand}
          itemLink={itemLink}
          currency={currency}
          position={position}
          stockStatus={stockStatus}
          price={price}
          priceRaw={priceRaw}
        />
      </Box>
    </ProductWrapper>
  );
};

export default Product;

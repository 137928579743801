import { gql } from "@apollo/client";

export const IS_LOGGED_IN = gql`
  query isLoggedIn(
    $languageId: Int!
    $erpSystem: String!
    $targetSystem: String!
  ) {
    isLoggedIn(
      languageId: $languageId
      erpSystem: $erpSystem
      targetSystem: $targetSystem
    ) {
      loginUrl
      loggedIn
      isGuestUser
      userType
      userId
      userSegment
      customerNumber
      organizationName
      userSalesChannel
    }
  }
`;

import React, { Fragment } from "react";
import { Tag, Icon } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { RemoveIcon } from "@eriksdigital/atomic-ui/components/Icons";

import {
  SectionWrapper,
  TagList,
  Span,
  Button,
  RemoveIconWrapper,
} from "./styles";
import { FormattedMessage } from "react-intl";
import { getTagLabelMessage } from "../../labels/tagLabel";

const renderFilter = (allFilters, currentFilter) => {
  const filterValue = allFilters[currentFilter];

  // Edge case for boolean values (e.g. inStock)
  if (filterValue === true) {
    return currentFilter;
  }

  // Edge case for arrays (e.g. brands);
  if (Array.isArray(filterValue)) {
    const renamedFilterValues = filterValue.map((filterVal) =>
      filterVal === "" ? "UNBRANDED" : filterVal
    );
    return renamedFilterValues.join(", ");
  }

  return filterValue;
};

const SelectedFilters = ({ filters, resetFilters, removeFilter }) => {
  const activeFilters = Object.keys(filters).filter(
    (filter) => filters[filter].length || filters[filter] === true
  );

  return (
    <SectionWrapper data-testid="filters">
      {activeFilters.length ? (
        <Fragment>
          <Span data-testid="filters-description">
            <FormattedMessage
              id="filters.description"
              defaultMessage="Your filters:"
              description="Label for Filter title"
            />
          </Span>

          <TagList data-testid="filters-list">
            {activeFilters.map((filter, index) => {
              return (
                <Tag key={`filter-${index}`} data-testid={`filter-${index}`}>
                  <RemoveIconWrapper onClick={() => removeFilter(filter)}>
                    <Icon
                      as={RemoveIcon}
                      color={colors.text.inverted}
                      size="sz16"
                    />
                  </RemoveIconWrapper>
                  {getTagLabelMessage(`tag-label.${filter}`)}:{" "}
                  {renderFilter(filters, filter)}
                </Tag>
              );
            })}
          </TagList>

          <Button
            data-testid="reset-filters"
            id="ghost"
            variant="ghost"
            onClick={resetFilters}
            text={
              <FormattedMessage
                id="filters.reset"
                defaultMessage="Reset all selections"
                description="Label for reset selection button"
              />
            }
          />
        </Fragment>
      ) : null}
    </SectionWrapper>
  );
};

SelectedFilters.defaultProps = {
  filters: {},
  resetFilters: null,
};

export default SelectedFilters;

import { useLazyQuery } from "@apollo/client";
import {
  getShoppingCart,
  getShoppingCartVariables,
} from "./schema/getShoppingCart";
import { GET_SHOPPING_CART } from "./getShoppingCart";

export const useShoppingCart = ({
  locale,
  erpSystem,
  targetSystem,
}: {
  locale: number;
  erpSystem: string;
  targetSystem: string;
}) => {
  const [fetchShoppingCart, { data, loading, error }] = useLazyQuery<
    getShoppingCart,
    getShoppingCartVariables
  >(GET_SHOPPING_CART, {
    variables: {
      locale,
      erpSystem: erpSystem.toUpperCase(),
      targetSystem,
    },
    fetchPolicy: "network-only",
  });

  return {
    fetchShoppingCart,
    shoppingCart: data,
    shoppingCartLoading: loading,
    shoppingCartError: error,
  };
};

import styled from "styled-components";
import { Button as unStyledButton } from "@eriksdigital/atomic-ui/components";
import { spacing } from "@eriksdigital/atomic-ui/styles";

export const SectionWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  padding: 10px;
`;

export const TagList = styled.div`
  display: flex;

  span + span {
    margin-left: 4px;
  }
`;

export const Span = styled.span`
  font-size: 14px;
  margin-right: 4px;
`;

export const Button = styled(unStyledButton)`
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
`;

export const RemoveIconWrapper = styled.span`
  margin-right: ${spacing.sp4};
  height: 17px;
  cursor: pointer;
`;

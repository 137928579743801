import React from "react";
import { PriceInformation } from "../PriceInformation";
import { AddToCart } from "../AddToCart";
import { PriceWrapper } from "./styles";
import { useLogin } from "../../hooks/useLoggedIn";

const Price = ({
  erpPartNumber,
  partNumber,
  englishDescription,
  brand,
  itemLink,
  currency,
  position,
  stockStatus,
  price,
  priceRaw,
}) => {
  const { loggedIn, isGuestUser } = useLogin();
  return loggedIn && !isGuestUser ? (
    <PriceWrapper display="flex" alignItems="center">
      <PriceInformation price={price} />
      <AddToCart
        partNumber={partNumber}
        brand={brand}
        priceRaw={priceRaw}
        englishDescription={englishDescription}
        erpPartNumber={erpPartNumber}
        currency={currency}
        position={position}
        link={itemLink}
        addToCartLoaing={!price}
        stockStatus={stockStatus}
      />
    </PriceWrapper>
  ) : null;
};

export { Price };

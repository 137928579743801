import React, { useContext } from "react";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { Button, Span, Icon } from "@eriksdigital/atomic-ui/components";
import { DocumentDownloadIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { FormattedMessage } from "react-intl";

export const DocumentDownload = ({
  documentUrl,
  partNumber,
}: {
  documentUrl: string;
  partNumber: string;
}) => {
  //@ts-ignore
  const { sendFileDownloadEvent } = useContext<any>(GTMContext);
  return (
    <Span>
      <Button
        variant="ghost"
        id={`download-${partNumber}`}
        data-testid={`download-${partNumber}`}
        as="a"
        href={documentUrl}
        target="_blank"
        onClick={() =>
          sendFileDownloadEvent({ fileName: `download-${partNumber}` })
        }
      >
        <FormattedMessage
          id="download.sheet"
          defaultMessage="Download data sheet"
          description="Label for download data sheet button"
        />{" "}
        <Icon as={DocumentDownloadIcon} size="sz32" />
      </Button>
    </Span>
  );
};

import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext, Logo } from "@eriksdigital/atomic-ui/components";
import { useQuery } from "@apollo/client";
import { StyledSelect } from "../Select/style";

import Hamburger from "../Hamburger";
import { LogInStatus } from "../LogInStatus";
import { ShoppingCart } from "../ShoppingCart";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel,
} from "./style";
import { constructUrl } from "../../utils/shopUtils";
import { GET_AVAILABLE_LANGUAGES } from "../../containers/ValveSelector/queries";
import { pimLocalesDropdown } from "../../utils/languageUtils";
import { ENVIRONMENT } from "../../constants";
import { useIntl } from "react-intl";
import { useLogin } from "../../hooks/useLoggedIn";

const environment = ENVIRONMENT;

const Header = () => {
  const { language, setLanguage, erpSystem } = useContext(LanguageContext);
  const [isMenuOpen, toggleMenu] = useState(false);
  const menu = useRef(null);
  const [languageDropdown, updateLanguageDropdown] = useState(
    Object.values(pimLocalesDropdown)
  );

  const { loggedIn } = useLogin();
  // const {data: user} = useQuery(IS_LOGGED_IN, {
  //   variables: {
  //     erpSystem: erpSystem.toUpperCase(),
  //     languageId: languageToCode(language),
  //     targetSystem: 'SEL_VAL'
  //   },
  // });
  // const userData = user && user.isLoggedIn;

  const { data } = useQuery(GET_AVAILABLE_LANGUAGES, {
    variables: {
      erpSystem: erpSystem.toUpperCase(),
    },
  });
  const availableLanguages = data && data.getAvailableLanguages;
  const intl = useIntl();

  useEffect(() => {
    if (availableLanguages) {
      const newDropdown = availableLanguages.map(
        (lang) => pimLocalesDropdown[lang.countryCode]
      );
      updateLanguageDropdown(newDropdown);
      if (!newDropdown.find((locale) => locale.value === language)) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({ target: { value } }) => {
    setLanguage(value);
  };

  const handleMenuToggle = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleClickOutside = (e) => {
    if (menu.current.contains(e.target)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            href={`${constructUrl(
              environment,
              erpSystem,
              language
            )}/${intl.formatMessage({
              id: "eriks-shop.valve-category",
              defaultMessage: "valves",
              description: "label for vale category",
            })}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo />
          </LogoWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            {loggedIn && <ShoppingCart />}
            <LogInStatus />
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
          </div>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang) => {
                return (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;

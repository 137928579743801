import styled from "styled-components";
import DownArrow from "../../assets/ic_down-arrow.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0px;
  max-width: 300px;
  width: 100%;

  div + label {
    margin-top: 10px;
  }
`;

export const StyledSelectLabel = styled.label`
  font-size: 0.75rem;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
`;

export const SelectWrapper = styled.div`
  background: url(${DownArrow}) no-repeat;
  background-position: right 10px bottom 50%;
  background-color: #fff;
  cursor: pointer;
`;
export const StyledSelect = styled.select`
  border: 1px solid #d2d0d0;
  padding: 0.5rem;
  padding-right: 25px;
  min-width: 100%;
  border-radius: 2px;
  background: transparent;
  background-image: none;
  appearance: none;

  &:focus {
    border: 1px solid rgba(0, 92, 169, 1);
  }
`;

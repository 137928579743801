import React, { useContext, useState, useEffect } from "react";

import Product from "../Product";

import { StyledProductList } from "./styles";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { languageToCode } from "../../utils/languageUtils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_LOGGED_IN_PRICE } from "../PriceInformation/queries";
import { GET_STOCK_LEVEL } from "../StockInformation/queries";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { FormattedMessage } from "react-intl";
import { useLogin } from "../../hooks/useLoggedIn";

const normalizeInitialProducts = (items) => {
  return items.map((item, index) => ({
    ...item,
    itemName: item.englishDescription,
    itemBrand: item.brand,
    itemId: item.partNumber,
    position: index + 1,
    stockStatus: { available: "Loading" },
    price: "",
    priceRaw: "",
  }));
};

const ProductList = ({ products = [] }) => {
  const [
    { didSendItemViewEvent, hasStockStatus, productsToRender, hasPrice },
    setProductsToRender,
  ] = useState({
    didSendItemViewEvent: false,
    hasStockStatus: false,
    hasPrice: false,
    productsToRender: normalizeInitialProducts(products),
  });
  const { loggedIn, isGuestUser, customerNumber } = useLogin();
  const { erpSystem, language } = useContext(LanguageContext);
  const { sendViewItemEvent } = useContext(GTMContext);

  const [
    getLoggedInPrice,
    { called: priceCalled, loading: priceLoading },
  ] = useMutation(GET_LOGGED_IN_PRICE, {
    onCompleted: (res) =>
      setProductsToRender((prevState) => {
        return {
          ...prevState,
          hasPrice: true,
          productsToRender: prevState.productsToRender.reduce((acc, cur) => {
            const curPrice = res.getLoggedInPriceV2.find(
              (priceObj) => priceObj.number === cur.erpPartNumber
            );
            return [
              ...acc,
              {
                ...cur,
                price: curPrice.price,
                priceRaw: curPrice.priceRaw,
              },
            ];
          }, []),
        };
      }),
  });

  const [
    getStockLevels,
    { called: stockCalled, loading: stockLoading },
  ] = useLazyQuery(GET_STOCK_LEVEL, {
    onCompleted: (res) => {
      setProductsToRender((prevState) => {
        return {
          ...prevState,
          hasStockStatus: true,
          productsToRender: prevState.productsToRender.reduce((acc, cur) => {
            const curStockStatus = res.getStockLevels.find(
              (stockStatus) => stockStatus.number === cur.erpPartNumber
            );
            return [
              ...acc,
              {
                ...cur,
                stockStatus: curStockStatus,
              },
            ];
          }, []),
        };
      });
    },
  });

  useEffect(() => {
    if (
      loggedIn &&
      !isGuestUser &&
      !priceCalled &&
      productsToRender.length > 0
    ) {
      const inputData = productsToRender.map((product) => ({
        itemNumber: product.erpPartNumber,
        unitCode: "PCE",
      }));
      getLoggedInPrice({
        variables: {
          customerNumber,
          currency: "EUR",
          languageId: languageToCode(language),
          shopId: 1,
          input: { items: inputData },
        },
      });
    }
  }, [
    loggedIn,
    isGuestUser,
    customerNumber,
    productsToRender,
    priceCalled,
    erpSystem,
    language,
    getLoggedInPrice,
  ]);

  useEffect(() => {
    if (productsToRender.length > 0 && !stockCalled && !stockLoading) {
      getStockLevels({
        variables: {
          erpSystem: erpSystem.toUpperCase(),
          languageId: languageToCode(language),
          targetSystem: "SEL_VAL",
          itemsNumbers: productsToRender.map(
            (product) => product.erpPartNumber
          ),
        },
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToRender, getStockLevels, erpSystem]);

  useEffect(() => {
    if (
      (loggedIn &&
        !stockLoading &&
        stockCalled &&
        hasStockStatus &&
        hasPrice &&
        !priceLoading &&
        priceCalled &&
        !didSendItemViewEvent) ||
      (!stockLoading && stockCalled && hasStockStatus && !didSendItemViewEvent)
    ) {
      sendViewItemEvent([
        ...productsToRender.map((product) => {
          return {
            ...product,
            itemStockStatus: product.stockStatus.available,
            price: product.priceRaw,
            index: product.position,
            materialNumber: product.erpPartNumber,
          };
        }),
      ]);
      setProductsToRender((prevState) => {
        return {
          ...prevState,
          didSendItemViewEvent: true,
          productsToRender: [...prevState.productsToRender],
        };
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stockLoading,
    stockCalled,
    hasStockStatus,
    priceCalled,
    priceLoading,
    productsToRender,
    didSendItemViewEvent,
    loggedIn,
  ]);
  if (!productsToRender.length) {
    return (
      <StyledProductList data-testid="product-list-empty">
        <p>
          <FormattedMessage
            id="no-results.description"
            defaultMessage="Your search did not match any results."
            description="Label for no product results"
          />
        </p>
      </StyledProductList>
    );
  }

  return (
    <StyledProductList data-testid="product-list">
      {productsToRender.length &&
        productsToRender.map((product, index) => {
          return <Product {...product} key={`product-number-${index}`} />;
        })}
    </StyledProductList>
  );
};

export default ProductList;

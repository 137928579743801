import { gql } from "@apollo/client";

export const GET_SHOPPING_CART = gql`
  query getShoppingCart(
    $locale: Int!
    $erpSystem: String!
    $targetSystem: String!
  ) {
    getShoppingCart(
      locale: $locale
      erpSystem: $erpSystem
      targetSystem: $targetSystem
    ) {
      x_grandTotal
      x_totalOrderItemCount
      orderItem {
        x_productImage
        x_erpPartNumber
        x_itemPrice
        x_itemNetValue
        x_itemStockAvailable
        partNumber
        x_productBrand
        x_productName
        quantity
        x_quantityMult
      }
    }
  }
`;

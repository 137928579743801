import { createGlobalStyle } from "styled-components";
import { fonts, fontFace } from "@eriksdigital/atomic-ui/styles";

const GlobalStyles = createGlobalStyle`
  ${fontFace}

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: ${fonts.fontFamily.primary};
    font-weight: ${fonts.fontWeight.normal};
  }

  main {
    margin: 0 auto;
    max-width: 1366px;
    padding: 0 16px;
  }

  body,
  p,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  fieldset,
  label {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  fieldset {
    border: none;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  select {
    appearance: none;
    background-image: none;
    background: transparent;
  }

  select::-ms-expand {
    visibility: hidden;
  }
`;

export default GlobalStyles;

import React from "react";
import { StockIndicator } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";

export type Statuses = {
  CompletelyOnStock: string;
  NothingOnStock: string;
  Loading: string;
  Unavailable: string;
};

const statuses = {
  CompletelyOnStock: {
    status: "inStock",
    message: (
      <FormattedMessage
        id="stock-info.CompletelyOnStock"
        defaultMessage="In stock"
        description="Label of Not price in search results section"
      />
    ),
  },
  NothingOnStock: {
    status: "outOfStock",
    message: (
      <FormattedMessage
        id="stock-info.NothingOnStock"
        defaultMessage="Out of stock"
        description="Label of out of stock in search results section"
      />
    ),
  },
  Loading: {
    status: "loading",
    message: (
      <FormattedMessage
        id="stock-info.loading"
        defaultMessage="Loading stock information"
        description="Label of loading in search results section"
      />
    ),
  },
  Unavailable: {
    status: "infoUnavailable",
    message: (
      <FormattedMessage
        id="stock-info.unavailable"
        defaultMessage="Stock information unavailable"
        description="Label of loading in search results section"
      />
    ),
  },
};

export const StockInformation = ({
  stockStatus,
}: {
  stockStatus: { available: keyof Statuses | null; quantity: number };
}) => {
  const statusText: keyof Statuses = stockStatus.available || "Unavailable";
  return (
    <StockIndicator
      quantity={stockStatus?.quantity}
      status={statuses[statusText]?.status}
      stockMessage={statuses[statusText]?.message}
    />
  );
};

/* IMPORTS */
import React, { Fragment, useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import {
  RadioButtonGroup,
  RadioButton,
  Checkbox,
  TextField,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";

import QuestionBox from "../QuestionBox";

import { GET_VALVE_ATTRIBUTES } from "../../containers/ValveSelector/queries";

import { Aside } from "./styles";
import Select from "../Select";
import useDebounce from "../../utils/useDebounce";
import { prepareAttributeQueryVariables } from "../../utils/prepareQueryVariables";
import { useIntl } from "react-intl";
import { getValidationLabelMessage } from "../../labels/validationLabels";
import { getCommonLabelMessage } from "../../labels/commonLabels";
import { getSideBarLabelMessage } from "../../labels/sideBarLabels";

const DEBOUNCE_MS = 400;

/* UTILS */

const attributeRenderer = ({ value }, index) => {
  return (
    <option key={`option-${index}`} value={value}>
      {value}
    </option>
  );
};

const checkboxRenderer = (
  data,
  state,
  loading,
  handleCheckboxToggle,
  questionName
) => {
  return data[questionName].map(({ value }) => {
    const checked = state[questionName].includes(value);

    return (
      <Checkbox
        disabled={loading}
        key={value}
        name={value}
        checked={checked}
        data-testid={`${value}-checkbox`}
        onChange={(e) =>
          handleCheckboxToggle.call(
            undefined,
            checked ? "REMOVE" : "ADD",
            questionName,
            e
          )
        }
        data-hj-whitelist
      >
        {value}
      </Checkbox>
    );
  });
};

const radioButtonRenderer = (serializedData, state, loading, questionName) => {
  return serializedData[questionName].map(({ value }) => (
    <RadioButton
      disabled={loading}
      key={value}
      id={value}
      value={value}
      data-testid={value}
      checked={state[questionName] === value}
      data-hj-whitelist
    >
      {value}
    </RadioButton>
  ));
};

/* MAIN COMPONENT */
const Sidebar = ({
  state,
  updateField,
  updateMultiField,
  resetTempAndPressure,
}) => {
  const [temporaryPressure, setTemporaryPressure] = useState("");
  const [temporaryTemperature, setTemporaryTemperature] = useState("");
  const { language, erpSystem } = useContext(LanguageContext);

  const { data, error, loading } = useQuery(GET_VALVE_ATTRIBUTES, {
    variables: {
      ...prepareAttributeQueryVariables(state),
      erpSystem: erpSystem.toUpperCase(),
      lang: language,
    },
  });

  const attributes = get(data, "getValveAttributes.attributes", {});

  const shouldRenderFilters = !error && Object.keys(attributes).length !== 0;
  const debouncedPressure = useDebounce(temporaryPressure, DEBOUNCE_MS);
  const debouncedTemperature = useDebounce(temporaryTemperature, DEBOUNCE_MS);
  const pressureError = isNaN(debouncedPressure);
  const pressureRequiredError = state["temperature"] && !state["pressure"];
  const temperatureError = isNaN(debouncedTemperature);
  const temperatureRequiredError = state["pressure"] && !state["temperature"];
  const intl = useIntl();
  const showBallboreFilter =
    state["constructionType"] === getCommonLabelMessage("3-way", intl);
  useEffect(() => {
    if (!isNaN(debouncedPressure)) {
      updateField(debouncedPressure, "pressure");
    }
  }, [debouncedPressure, updateField]);

  useEffect(() => {
    if (!isNaN(debouncedTemperature)) {
      updateField(debouncedTemperature, "temperature");
    }
  }, [debouncedTemperature, updateField]);

  useEffect(() => {
    setTemporaryPressure("");
    setTemporaryTemperature("");
  }, [language, erpSystem, resetTempAndPressure]);

  const handleDropdownChange = ({ currentTarget: { name, value } }) => {
    updateField(value, name);
  };

  const handleRadioButtonChange = (e) => {
    const value = get(e.currentTarget, "value", "");
    const id = get(e.currentTarget, "name", "");

    updateField(value, id);
  };

  const handleCheckboxToggle = (
    action,
    name,
    { currentTarget: { name: type } }
  ) => {
    updateMultiField(type, name, action);
  };

  return (
    <Aside data-testid="sidebar">
      {error ? <div>{JSON.stringify(error)}</div> : null}

      {shouldRenderFilters && (
        <Fragment>
          <QuestionBox
            data-testid="brands"
            title={getSideBarLabelMessage("filter.by-brand")}
          >
            {attributes.brand &&
              attributes.brand.map(({ value }) => {
                const checked = state["brand"].includes(value);

                return (
                  <Checkbox
                    key={`${value}-checkbox`}
                    name={value}
                    data-testid={`${value}-checkbox`}
                    checked={checked}
                    disabled={loading}
                    onChange={(e) =>
                      handleCheckboxToggle.call(
                        undefined,
                        checked ? "REMOVE" : "ADD",
                        "brand",
                        e
                      )
                    }
                    data-hj-whitelist
                  >
                    {value === "" ? `UNBRANDED` : `${value}`}
                  </Checkbox>
                );
              })}
          </QuestionBox>

          {attributes.constructionType && (
            <QuestionBox
              data-testid="constructionType"
              title={getSideBarLabelMessage("constructionType.label")}
            >
              <RadioButtonGroup
                name="constructionType"
                data-testid="constructionType-radiogroup"
                onChange={handleRadioButtonChange}
              >
                <RadioButton
                  disabled={loading}
                  key={"All"}
                  id={"constructionType-All"}
                  value={""}
                  data-testid={"constructionType-All"}
                  checked={state["constructionType"] === ""}
                  data-hj-whitelist
                >
                  All
                </RadioButton>
                {radioButtonRenderer(
                  attributes,
                  state,
                  loading,
                  "constructionType"
                )}
              </RadioButtonGroup>
            </QuestionBox>
          )}

          {showBallboreFilter && attributes.ballBore && (
            <QuestionBox
              data-testid="ballBore"
              title={getSideBarLabelMessage("ballBore.label")}
            >
              <RadioButtonGroup
                name="ballBore"
                data-testid="ballBore-radiogroup"
                onChange={handleRadioButtonChange}
              >
                <RadioButton
                  disabled={loading}
                  key={"All"}
                  id={"ballBore-All"}
                  value={""}
                  data-testid={"ballBore-All"}
                  checked={state["ballBore"] === ""}
                  data-hj-whitelist
                >
                  All
                </RadioButton>
                {radioButtonRenderer(attributes, state, loading, "ballBore")}
              </RadioButtonGroup>
            </QuestionBox>
          )}

          {attributes.connection && (
            <QuestionBox
              data-testid="connection"
              title={getSideBarLabelMessage("connection.label")}
            >
              <Select
                data-testid="connection-dropdown"
                name="connection"
                id="connection-dropdown"
                options={attributes.connection}
                renderOptions={(option, index) =>
                  attributeRenderer(option, index)
                }
                value={state["connection"]}
                defaultKey="ALL"
                includeDefault
                onChange={handleDropdownChange}
                disabled={loading}
              />
            </QuestionBox>
          )}
          {attributes.faceToFaceLength && (
            <QuestionBox
              data-testid="faceToFaceLength"
              title={getSideBarLabelMessage("faceToFaceLength.label")}
              name="faceToFaceLength"
              info={getSideBarLabelMessage("faceToFaceLength.tooltip")}
            >
              <Select
                data-testid="faceToFaceLength-dropdown"
                name="faceToFaceLength"
                id="faceToFaceLength-dropdown"
                options={attributes.faceToFaceLength}
                renderOptions={(option, index) =>
                  attributeRenderer(option, index)
                }
                value={state["faceToFaceLength"]}
                defaultKey="ALL"
                includeDefault
                onChange={handleDropdownChange}
                disabled={loading}
              />
            </QuestionBox>
          )}
          {attributes.housingMaterial && (
            <QuestionBox
              data-testid="housingMaterial"
              title={getSideBarLabelMessage("housingMaterial.label")}
              name="housingMaterial"
            >
              {checkboxRenderer(
                attributes,
                state,
                loading,
                handleCheckboxToggle,
                "housingMaterial"
              )}
            </QuestionBox>
          )}

          {attributes.pressureRating && (
            <QuestionBox
              data-testid="pressureRating"
              title={getSideBarLabelMessage("pressureRating.label")}
              name="pressureRating"
              info={getSideBarLabelMessage("pressureRating.tooltip")}
            >
              <Select
                data-testid="pressureRating-dropdown"
                name="pressureRating"
                id="pressureRating-dropdown"
                options={attributes.pressureRating}
                renderOptions={(option, index) =>
                  attributeRenderer(option, index)
                }
                value={state["pressureRating"]}
                onChange={handleDropdownChange}
                defaultKey="ALL"
                includeDefault
                disabled={loading}
              />
            </QuestionBox>
          )}

          {attributes.norm && (
            <QuestionBox
              name="norm"
              data-testid="norm"
              title={getSideBarLabelMessage("norm.label")}
            >
              <RadioButtonGroup
                name="norm"
                data-testid="norm-radiogroup"
                onChange={handleRadioButtonChange}
              >
                <RadioButton
                  disabled={loading}
                  key={"All"}
                  id={"norm-All"}
                  value={""}
                  data-testid={"norm-All"}
                  checked={state["norm"] === ""}
                  data-hj-whitelist
                >
                  All
                </RadioButton>
                {radioButtonRenderer(attributes, state, loading, "norm")}
              </RadioButtonGroup>
            </QuestionBox>
          )}

          {attributes.nominalInnerDiameter && (
            <QuestionBox
              data-testid="nominalInnerDiameter"
              title={getSideBarLabelMessage("nominalInnerDiameter.label")}
              name="nominalInnerDiameter"
            >
              <Select
                data-testid="nominalInnerDiameter-dropdown"
                id="nominalInnerDiameter-dropdown"
                name="nominalInnerDiameter"
                options={attributes.nominalInnerDiameter}
                renderOptions={(option, index) =>
                  attributeRenderer(option, index)
                }
                value={state["nominalInnerDiameter"]}
                onChange={handleDropdownChange}
                defaultKey="ALL"
                includeDefault
                disabled={loading}
              />
            </QuestionBox>
          )}

          {attributes.operation && (
            <QuestionBox
              data-testid="operation"
              title={getSideBarLabelMessage("operation.label")}
            >
              <Select
                name="operation"
                data-testid="operation-dropdown"
                id="operation-dropdown"
                options={attributes.operation}
                renderOptions={(option, index) =>
                  attributeRenderer(option, index)
                }
                value={state["operation"]}
                onChange={handleDropdownChange}
                defaultKey="ALL"
                includeDefault
                disabled={loading}
              />
            </QuestionBox>
          )}

          {attributes.pressureTemperatureMeta && (
            <QuestionBox
              data-testid="conditions"
              title={getSideBarLabelMessage("conditions.label")}
              info={
                !attributes.pressureTemperatureMeta &&
                getSideBarLabelMessage(
                  "pressureTemperatureMeta.no-info-available-tooltip"
                )
              }
            >
              <TextField
                name="pressure"
                id="pressure"
                data-testid="pressure"
                value={temporaryPressure}
                onChange={(e) => setTemporaryPressure(e.target.value)}
                error={pressureRequiredError || pressureError}
                label={getSideBarLabelMessage("pressure.label")}
                disabled={!attributes.pressureTemperatureMeta || loading}
                placeholder={intl.formatMessage({
                  id: "pressure.placeholder",
                  defaultMessage: "Pressure",
                  description: "label for pressure placeholder",
                })}
                errorMessage={
                  pressureRequiredError
                    ? getValidationLabelMessage(
                        "validation.require-pressure",
                        intl
                      )
                    : getValidationLabelMessage("validation.isNumber", intl)
                }
                data-hj-whitelist
              />
              <TextField
                name="temperature"
                id="temperature"
                data-testid="temperature"
                value={temporaryTemperature}
                onChange={(e) => setTemporaryTemperature(e.target.value)}
                error={temperatureRequiredError || temperatureError}
                label={getSideBarLabelMessage("temperature.label")}
                placeholder={intl.formatMessage({
                  id: "temperature.placeholder",
                  defaultMessage: "Temperature",
                  description: "label of temperature placeholder",
                })}
                disabled={!attributes.pressureTemperatureMeta || loading}
                errorMessage={
                  temperatureRequiredError
                    ? getValidationLabelMessage(
                        "validation.require-temperature",
                        intl
                      )
                    : getValidationLabelMessage("validation.isNumber", intl)
                }
                data-hj-whitelist
              />
            </QuestionBox>
          )}
        </Fragment>
      )}
    </Aside>
  );
};
export default Sidebar;

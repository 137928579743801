import styled from "styled-components";

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 328px;

  > div + div {
    border-top: none;
  }
`;

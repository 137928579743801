import styled from "styled-components";
import { fonts, sizes, spacing, colors } from "@eriksdigital/atomic-ui/styles";

export const ProductWrapper = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.default.gray};
  display: flex;
  padding: ${spacing.sp8} 0;

  &:last-child {
    border-bottom: 1px solid ${colors.default.gray};
  }
`;

export const ProductImage = styled.img`
  flex-shrink: 0;
  height: ${sizes.sz80};
  width: ${sizes.sz80};
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding: ${spacing.sp16};
  width: 100%;
`;

export const Span = styled.span`
  color: ${(props) => (props.color ? props.color : colors.default.darkGray)};
  font-size: ${fonts.fontSize.ft14};
`;

import { gql } from "@apollo/client";

export const GET_LOGGED_IN_PRICE = gql`
  mutation getLoggedInPriceV2(
    $customerNumber: String
    $currency: String
    $languageId: Int
    $shopId: Int
    $input: ItemRequestInputV2
  ) {
    getLoggedInPriceV2(
      customerNumber: $customerNumber
      currency: $currency
      shopId: $shopId
      languageId: $languageId
      input: $input
    ) {
      number
      price
      priceRaw
      currency
    }
  }
`;

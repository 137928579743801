import React, {
  Fragment,
  useState,
  useReducer,
  useEffect,
  useContext,
  useCallback,
} from "react";
import get from "lodash/get";
import { GTMContext } from "@eriksdigital/gs-ga4";
import {
  LanguageContext,
  Button,
  Icon,
} from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { LoadingIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { useLazyQuery } from "@apollo/client";

import Sidebar from "../../components/Sidebar";
import ProductList from "../../components/ProductList";
import SelectedFilters from "../../components/SelectedFilters";

import { GET_VALVE_ITEMS } from "./queries";
import { prepareQueryVariables } from "../../utils/prepareQueryVariables";

import {
  MainTitle,
  MainWrapper,
  Content,
  BottomActions,
  LoadingContainer,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { getCommonLabelMessage } from "../../labels/commonLabels";
import { useLogin } from "../../hooks/useLoggedIn";
export const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD":
      return {
        ...state,
        [action.payload.id]: [
          ...state[action.payload.id],
          action.payload.value,
        ],
      };
    case "REMOVE":
      return {
        ...state,
        [action.payload.id]: state[action.payload.id].filter(
          (val) => val !== action.payload.value
        ),
      };
    case "RESET":
      return { ...action.payload };
    default:
      return state;
  }
};

const ValveSelector = () => {
  const { language, erpSystem } = useContext(LanguageContext);
  const {
    sendNavigationEvent,
    sendAddFilterEvent,
    sendRemoveFilterEvent,
    isReadyToSendEvents,
    sendPageViewEvent,
  } = useContext(GTMContext);
  const initialState = {
    constructionType: "",
    ballBore: "",
    nominalInnerDiameter: "",
    connection: "",
    faceToFaceLength: "",
    housingMaterial: [],
    pressureRating: "",
    operation: "",
    norm: "",
    temperature: "",
    pressure: "",
    brand: [],
    sort: "DESC",
    orderBy: "productSequence",
    limit: 20,
    lang: language,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [valveItems, setValveItems] = useState([]);
  const [loadingValves, setLoadingValves] = useState(false);
  const [resetTempAndPressure, executeResetTempAndPressure] = useState(false);
  const intl = useIntl();
  const {
    userType,
    userId,
    userSegment,
    loggedIn,
    loginLoading,
    customerNumber,
    organizationName,
    userSalesChannel,
  } = useLogin();
  const handleGetValveItems = ({ getValveItems: { valves } }) => {
    setValveItems(valves);
    setLoadingValves(false);
  };

  const [getValves, { data, error }] = useLazyQuery(GET_VALVE_ITEMS, {
    onCompleted: (res) => res && handleGetValveItems(res),
  });

  useEffect(() => {
    if (isReadyToSendEvents && sendPageViewEvent && loginLoading === false) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber || "",
        organizationName: organizationName || "",
        userSalesChannel,
        userType,
        userId,
        userSegment,
        loggedIn,
      });
    }
  }, [
    isReadyToSendEvents,
    sendPageViewEvent,
    erpSystem,
    loginLoading,
    loggedIn,
    userId,
    userSegment,
    userType,
    customerNumber,
    organizationName,
    userSalesChannel,
  ]);

  const { brands, hasMore } = get(data, "getValveItems", {
    valves: [],
    hasMore: false,
  });
  const { sort, orderBy, limit, lang, ...filters } = state;

  useEffect(() => {
    setLoadingValves(true);
    getValves({
      variables: prepareQueryVariables({
        ...state,
        erpSystem: erpSystem.toUpperCase(),
      }),
    });
  }, [getValves, state, erpSystem]);

  // Refetch data if the language changes & reset the state
  // (because material names come translated from the backend)
  useEffect(() => {
    dispatch({
      type: "UPDATE",
      payload: { ...initialState, lang: language },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const updateField = useCallback((value, id) => {
    if ((id === "pressure" || id === "temperature") && isNaN(value)) {
      return false;
    }
    if (value !== "") {
      sendAddFilterEvent({ name: value, category: id });
    } else {
      sendRemoveFilterEvent({ name: "", category: id });
    }

    // If the ballBore field is hidden, we need to reset its value
    if (
      id === "constructionType" &&
      value !== getCommonLabelMessage("3-way", intl)
    ) {
      if (state.ballBore !== "") {
        sendRemoveFilterEvent({ name: "", category: "ballBore" });
      }
      dispatch({
        type: "UPDATE",
        payload: {
          ballBore: "",
        },
      });
    }
    return dispatch({
      type: "UPDATE",
      payload: {
        [id]: value,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMultiField = useCallback((value, id, action) => {
    if (action === "ADD") {
      sendAddFilterEvent({ name: value, category: id });
    }
    if (action === "REMOVE") {
      sendRemoveFilterEvent({ name: value, category: id });
    }
    dispatch({
      type: action,
      payload: {
        id,
        value,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilters = () => {
    executeResetTempAndPressure((v) => !v);
    dispatch({ type: "RESET", payload: initialState });
  };

  const handleRemoveFilter = (filter) => {
    // nothing goes through this
    dispatch({
      type: "UPDATE",
      payload: {
        [filter]: initialState[filter],
      },
    });
  };

  const showMore = () => {
    const newLimit = limit + initialState.limit;
    dispatch({
      type: "UPDATE",
      payload: { limit: newLimit },
    });
    sendNavigationEvent();
  };
  return (
    <Fragment>
      <MainTitle>
        <FormattedMessage
          id="main-title"
          defaultMessage="Ball valve selector"
          description="Application title"
        />
      </MainTitle>

      <MainWrapper>
        <Sidebar
          updateField={updateField}
          updateMultiField={updateMultiField}
          state={state}
          brands={brands}
          resetTempAndPressure={resetTempAndPressure}
        />
        <Content>
          <SelectedFilters
            filters={filters}
            resetFilters={handleResetFilters}
            removeFilter={handleRemoveFilter}
          />

          {loadingValves ? (
            <LoadingContainer>
              <Icon as={LoadingIcon} size="xxl" color={colors.default.blueD} />
            </LoadingContainer>
          ) : null}
          {error ? <div>{JSON.stringify(error)}</div> : null}
          {!loadingValves && !error && (
            <Fragment>
              <ProductList products={valveItems} />
              {hasMore ? (
                <BottomActions>
                  <Button
                    id={"moreResults"}
                    data-testid="showMoreButton"
                    onClick={showMore}
                    isLoading={loadingValves}
                    disabled={loadingValves}
                  >
                    <FormattedMessage
                      id="results.moreResults"
                      defaultMessage="Show more results"
                      description="Label for more results button"
                    />
                  </Button>
                </BottomActions>
              ) : null}
            </Fragment>
          )}
        </Content>
      </MainWrapper>
    </Fragment>
  );
};

export default ValveSelector;

import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "./getLoggedIn";
import { languageToCode } from "../utils/languageUtils";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";

export const useLogin = () => {
  const { language, erpSystem } = useContext<any>(LanguageContext);
  const { data, loading, error, refetch } = useQuery(IS_LOGGED_IN, {
    variables: {
      erpSystem: erpSystem.toUpperCase(),
      languageId: languageToCode(language),
      targetSystem: "SEL_VAL",
    },
  });
  return {
    fetchLoginStatus: refetch,
    loginUrl: data?.isLoggedIn?.loginUrl,
    loggedIn: data?.isLoggedIn?.loggedIn,
    isGuestUser: data?.isLoggedIn?.isGuestUser,
    userType: data?.isLoggedIn?.userType,
    userSegment: data?.isLoggedIn?.userSegment,
    userId: data?.isLoggedIn?.userId,
    customerNumber: data?.isLoggedIn?.customerNumber,
    organizationName: data?.isLoggedIn?.organizationName,
    userSalesChannel: data?.isLoggedIn?.userSalesChannel,
    loginLoading: loading,
    error,
  };
};

import styled from "styled-components";
import { colors } from "@eriksdigital/atomic-ui/styles";

export const Box = styled.div`
  border: 1px solid ${colors.default.gray};
  border-left: none;
  padding: 20px;

  label {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  > div + div {
    padding-top: 20px;
  }
`;

export const Label = styled.label``;

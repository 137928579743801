import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LanguageContext, Icon } from "@eriksdigital/atomic-ui/components";

import { colors } from "@eriksdigital/atomic-ui/styles";
import {
  LoadingIcon,
  CartIcon,
} from "@eriksdigital/atomic-ui/components/Icons";

import { IconWrapper, ItemCountCircle } from "./style";
import { GET_SALES_ORGANIZATIONS } from "./queries";
import { getSalesOrganizations } from "./schema/getSalesOrganizations";
import { constructShoppingUrl } from "../../utils/shopUtils";
import { useShoppingCart } from "../../hooks/useShoppingCart";
import { languageToCode } from "../../utils/languageUtils";

export const ShoppingCart = () => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const {
    shoppingCart,
    shoppingCartLoading,
    fetchShoppingCart,
  } = useShoppingCart({
    locale: languageToCode(language),
    erpSystem: erpSystem,
    targetSystem: "SEL_VAL",
  });
  const { data } = useQuery<getSalesOrganizations>(GET_SALES_ORGANIZATIONS);
  const x_totalOrderItemCount =
    shoppingCart?.getShoppingCart?.x_totalOrderItemCount ?? 0;

  useEffect(() => {
    fetchShoppingCart();
  }, [fetchShoppingCart]);

  return (
    <div id={"navbar-cart-icon"}>
      <a
        href={
          data &&
          language &&
          erpSystem &&
          constructShoppingUrl(data, language, erpSystem)
        }
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle isLargeAmount={x_totalOrderItemCount > 99}>
            {shoppingCartLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </a>
    </div>
  );
};

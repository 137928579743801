import styled from "styled-components";
import { H2 } from "@eriksdigital/atomic-ui/components";

export const MainTitle = styled(H2)`
  margin-bottom: 30px;
  margin-left: 328px; /* 328px from sidebar */
  padding-left: 30px;
`;

export const MainWrapper = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 30px;
`;

export const ResultActions = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ItemCount = styled.div`
  margin-bottom: 8px;
  margin-right: 20px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomActions = styled(ResultActions)`
  margin-bottom: 40px;
  > div {
    margin-left: auto;
  }
`;

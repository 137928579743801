const languages = {
  nl: 31,
  en: -1,
  fr: -2,
  de: -3,
};

export const erpSystems = ["nl", "be", "ch", "uk", "lu", "de", "fr"];

export const languageToCode = (language) => {
  return languages[language];
};

export const fetchTranslations = ({ language = "en" }) => {
  if (process.env.REACT_APP_USE_MOCK_TRANSLATIONS === "yes") {
    return import(`../mocks/locales/${language}`).then((module) => {
      return module.default;
    });
  } else {
    return import(`../../locales/${language}`).then((module) => {
      return module.default;
    });
  }
};

export const pimLocalesDropdown = {
  "31": {
    value: "nl",
    label: "Nederlands",
  },
  "-1": {
    value: "en",
    label: "English",
  },
  "-2": {
    value: "fr",
    label: "Français",
  },
  "-3": {
    value: "de",
    label: "Deutsch",
  },
};

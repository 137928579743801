import React from "react";

import { defaultTheme } from "@eriksdigital/atomic-ui/styles";

import Header from "./components/Header";
import ValveSelector from "./containers/ValveSelector";
import { NotificationProvider } from "./utils/NotificationContext";
import { NotificationCenter } from "./components/NotificationCenter";
import { ThemeProvider } from "styled-components";
import { TranslationProvider } from "./providers/TranslationProvider";

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <TranslationProvider>
        <NotificationProvider>
          <Header />
          <ValveSelector />
          <NotificationCenter />
        </NotificationProvider>
      </TranslationProvider>
    </ThemeProvider>
  );
};

export default App;

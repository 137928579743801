export const getValidationLabelMessage = (keyLabel, intl) => {
  return {
    "validation.isNumber": intl.formatMessage({
      id: "validation.isNumber",
      defaultMessage: "Please insert a valid number",
      description: "label of temperature field validation",
    }),
    "validation.require-temperature": intl.formatMessage({
      id: "validation.require-temperature",
      defaultMessage: "Please provide temperature",
      description: "label of temperature field validation",
    }),
    "validation.require-pressure": intl.formatMessage({
      id: "validation.require-pressure",
      defaultMessage: "Please provide pressure",
      description: "label of pressure field validation",
    }),
  }[keyLabel];
};

import {
  getSalesOrganizations,
  getSalesOrganizations_getSalesOrganizations,
} from "../components/ShoppingCart/schema/getSalesOrganizations";
import { languageToCode } from "./languageUtils";

type environmentTypes = "test" | "qa" | "acc" | "prod";

const environments = {
  test: "dev.",
  qa: "test.",
  acc: "test.",
  prod: "",
};

type shopNameTypes = "nl" | "be" | "lu" | "fr" | "ch" | "uk" | "de";

const shopNames = {
  nl: "shop.eriks.nl/",
  be: "shop.eriks.be/",
  lu: "shop.eriks.lu/",
  fr: "shop.eriks.fr/",
  ch: "shop.maagtechnic.ch/",
  uk: "shop.eriks.co.uk/",
  de: "shop.eriks.de/",
};

export const constructUrl = (
  environment: environmentTypes = "test",
  erpSystem: shopNameTypes = "nl",
  language: string = "nl"
) => {
  return `https://${environments[environment]}${shopNames[erpSystem]}${language}`;
};

export const constructShoppingUrl = (
  data: getSalesOrganizations,
  language: string,
  erpSystem: string
) => {
  if (data === null || data.getSalesOrganizations === null) return "/";
  const salesOrganization:
    | getSalesOrganizations_getSalesOrganizations
    | undefined = data.getSalesOrganizations.find(
    (org: getSalesOrganizations_getSalesOrganizations) =>
      org.country === erpSystem.toUpperCase()
  );
  if (!salesOrganization) return "/";
  return `${
    salesOrganization.shop_url
  }ShoppingCartDisplay?catalogId=1000&langId=${languageToCode(
    language
  )}&storeId=${salesOrganization.store_id}`;
};

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { GTMProvider } from "@eriksdigital/gs-ga4";
import { createApolloClient } from "./createApolloClient";

import App from "./App";

import GlobalStyles from "./styles/GlobalStyles";

// eslint-disable-next-line
const uri = "/valve-selector/api";

export const client = createApolloClient(uri, true);

// eslint-disable-next-line
if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <GTMProvider env={process.env.REACT_APP_ENVIRONMENT}>
      <main>
        <App />
      </main>
      <GlobalStyles />
    </GTMProvider>
  </ApolloProvider>,
  document.getElementById("root") || document.createElement("div")
);

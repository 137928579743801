import { gql } from "@apollo/client";

export const ADD_TO_CART = gql`
  mutation addToCart(
    $locale: Int
    $erpSystem: String
    $erpPartNo: String!
    $quantity: Int
    $targetSystem: String
  ) {
    addToCart(
      locale: $locale
      erpSystem: $erpSystem
      erpPartNo: $erpPartNo
      quantity: $quantity
      targetSystem: $targetSystem
    ) {
      responseUrl
    }
  }
`;

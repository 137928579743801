import { FormattedMessage } from "react-intl";
import React from "react";

export const getSideBarLabelMessage = (keyLabel) => {
  return {
    "filter.by-brand": (
      <FormattedMessage
        id="filter.by-brand"
        defaultMessage="Which brand do you prefer?"
        description="Label for title brand"
      />
    ),
    "constructionType.label": (
      <FormattedMessage
        id="constructionType.label"
        defaultMessage="What is the functionality of your valve?"
        description="Label for title functionality"
      />
    ),
    "ballBore.label": (
      <FormattedMessage
        id="ballBore.label"
        defaultMessage="Do you need an L- or a T-bore?"
        description="Label for ballBore title"
      />
    ),
    "connection.label": (
      <FormattedMessage
        id="connection.label"
        defaultMessage="What is your connection type?"
        description="Label for title connection type"
      />
    ),
    "faceToFaceLength.label": (
      <FormattedMessage
        id="faceToFaceLength.label"
        defaultMessage="Face to Face length, mm"
        description="Label for face to face title"
      />
    ),
    "faceToFaceLength.tooltip": (
      <FormattedMessage
        id="faceToFaceLength.tooltip"
        defaultMessage="Select the most suitable length"
        description="Label for the most suitable length"
      />
    ),
    "housingMaterial.label": (
      <FormattedMessage
        id="housingMaterial.label"
        defaultMessage="Which material is the body made of?"
        description="Label for housingMaterial title"
      />
    ),
    "pressureRating.label": (
      <FormattedMessage
        id="pressureRating.label"
        defaultMessage="What is the pressure rating of your system?"
        description="Label for pressureRating title"
      />
    ),
    "norm.label": (
      <FormattedMessage
        id="norm.label"
        defaultMessage="What norm do you require?"
        description="Label for norm title"
      />
    ),
    "nominalInnerDiameter.label": (
      <FormattedMessage
        id="nominalInnerDiameter.label"
        defaultMessage="What is the nominal inner diameter?"
        description="Label for nominalInnerDiameter title"
      />
    ),
    "operation.label": (
      <FormattedMessage
        id="operation.label"
        defaultMessage="Which operating mechanism do you prefer?"
        description="Label for operation title"
      />
    ),
    "conditions.label": (
      <FormattedMessage
        id="conditions.label"
        defaultMessage="What are your process conditions?"
        description="Label for pressureTemperatureMeta title"
      />
    ),
    "pressureTemperatureMeta.no-info-available-tooltip": (
      <FormattedMessage
        id="pressureTemperatureMeta.no-info-available-tooltip"
        defaultMessage="Selection based on pressure in combination with temperature is not yet possible for this brand."
        description="Tooltip label for pressureTemperatureMeta title"
      />
    ),
    "pressure.label": (
      <FormattedMessage
        id="pressure.label"
        defaultMessage="What is the working pressure [bar]?"
        description="Label for pressure title"
      />
    ),
    "temperature.label": (
      <FormattedMessage
        id="temperature.label"
        defaultMessage="What is the medium temperature (°C)?"
        description="Label for temperature title"
      />
    ),
    "pressureRating.tooltip": (
      <FormattedMessage
        id="pressureRating.tooltip"
        defaultMessage="For some sizes multiple pressure classes are suitable, choose the highest pressure class when this is the case (like PN16/PN40)."
        description="Tooltip for pressureRating title"
      />
    ),
  }[keyLabel];
};

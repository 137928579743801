import React from "react";
import { TooltipHost, Icon } from "@eriksdigital/atomic-ui/components";
import { TooltipIcon } from "@eriksdigital/atomic-ui/components/Icons";

import { Box, Label } from "./styles";

const QuestionBox = ({ title, children, name, info, ...rest }) => {
  return (
    <Box {...rest}>
      <Label>
        {title}
        {info && (
          <span>
            <Icon
              id={`info-${name}`}
              as={TooltipIcon}
              color="#000"
              size="sz16"
            />
          </span>
        )}
      </Label>

      {children}

      {info && (
        <TooltipHost parent={`info-${name}`}>
          <span>{info}</span>
        </TooltipHost>
      )}
    </Box>
  );
};

export default QuestionBox;

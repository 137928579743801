import pickBy from "lodash/pickBy";

export const prepareQueryVariables = (state) => {
  const fields = pickBy(state, (val) => val !== "");

  const housingMaterial =
    state.housingMaterial.length > 0
      ? { housingMaterial: state.housingMaterial }
      : {};
  const brand =
    state.brand.length > 0
      ? { brands: state.brand.map((b) => (b === "UNBRANDED" ? "" : b)) }
      : {};
  const temperature =
    state.temperature && state.pressure
      ? { temperature: state.temperature }
      : {};
  const pressure =
    state.temperature && state.pressure ? { pressure: state.pressure } : {};

  const {
    housingMaterial: _,
    brand: __,
    pressure: ___,
    temperature: ____,
    ...rest
  } = fields;
  //const brands = {...brand};
  return { ...rest, ...housingMaterial, ...brand, ...pressure, ...temperature };
};

export const prepareAttributeQueryVariables = (state) => {
  const fields = prepareQueryVariables(state);
  const { sort, orderBy, limit, page, ...rest } = fields;
  return { ...rest };
};

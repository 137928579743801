import { useMutation } from "@apollo/client";
import {
  addToCart as addToCartInterface,
  addToCartVariables,
} from "./schema/addToCart";
import { ApolloError } from "@apollo/client";
import { ADD_TO_CART } from "./getAddToCart";

export const useAddToCart = ({
  onCompleted,
  onError,
}: {
  onCompleted: (data: addToCartInterface) => void;
  onError: (error: ApolloError) => void;
}) => {
  const [
    addToCart,
    { data: addToCartData, loading: addToCartLoading, error: addToCartError },
  ] = useMutation<addToCartInterface, addToCartVariables>(ADD_TO_CART, {
    onCompleted,
    onError,
  });

  return {
    addToCart,
    addToCartData,
    addToCartLoading,
    addToCartError,
  };
};

export default useAddToCart;

import React, { useContext } from "react";
import {
  AddToCart as A2C,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { NotificationContext } from "../../utils/NotificationContext";
import { languageToCode } from "../../utils/languageUtils";
import { FormattedMessage } from "react-intl";
import { useShoppingCart } from "../../hooks/useShoppingCart";
import useAddToCart from "../../hooks/useAddToCart";

export const AddToCart = ({
  brand,
  priceRaw,
  englishDescription,
  partNumber,
  erpPartNumber,
  position,
  stockStatus,
}: {
  brand: string;
  priceRaw: number;
  englishDescription: string;
  partNumber: string;
  erpPartNumber: string;
  currency: string;
  position: number;
  stockStatus: { available: string };
}) => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const { createNotification } = useContext<any>(NotificationContext);
  const { fetchShoppingCart } = useShoppingCart({
    locale: languageToCode(language),
    erpSystem: erpSystem.toUpperCase(),
    targetSystem: "SEL_VAL",
  });
  //@ts-ignore
  const { sendAddToCartEvent } = useContext<any>(GTMContext);

  const handleAddToCartSuccess = () => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${partNumber}`,
        variant: "success",
        message: (
          <FormattedMessage
            id="add-to-cart.success"
            defaultMessage="The product was added to your shopping cart"
            description="Item is succesfully added to the shoping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
  };

  const handleAddToCartError = () => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${partNumber}`,
        variant: "error",
        message: (
          <FormattedMessage
            id="add-to-cart.error"
            defaultMessage="Our apologies! Something went wrong when adding the product to your shopping cart. Please contact the ERIKS support team."
            description="Item was not added to the shoping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
  };

  const { addToCart } = useAddToCart({
    onCompleted: handleAddToCartSuccess,
    onError: handleAddToCartError,
  });

  const handleAddToCart = async (quantity: number) => {
    sendAddToCartEvent &&
      sendAddToCartEvent({
        itemId: partNumber,
        price: priceRaw,
        itemBrand: brand,
        itemStockStatus: stockStatus.available,
        itemName: englishDescription,
        index: position,
        quantity: quantity,
        materialNumber: erpPartNumber,
      });
    addToCart({
      variables: {
        locale: languageToCode(language),
        erpSystem: erpSystem.toUpperCase(),
        erpPartNo: erpPartNumber,
        quantity,
        targetSystem: "SEL_VAL",
      },
    });
  };

  return (
    <A2C
      table
      id={`add-to-cart-${partNumber}`}
      data-testid={`add-to-cart-${partNumber}`}
      // quantityOnChange={setQuantityGA}
      onAdd={handleAddToCart}
      addLabel="Add shopping basket"
      quantityInitialValue={1}
    />
  );
};

import React from "react";
import {
  StyledSelect,
  StyledSelectLabel,
  SelectWrapper,
  Wrapper,
} from "./style";
import { getCommonLabelMessage } from "../../labels/commonLabels";
import { useIntl } from "react-intl";

export const Select = ({
  id,
  options = [],
  renderOptions,
  label,
  name,
  onChange,
  value,
  includeDefault,
  defaultKey,
}) => {
  const intl = useIntl();
  return (
    <Wrapper>
      {label && <StyledSelectLabel>{label}</StyledSelectLabel>}
      <SelectWrapper>
        <StyledSelect
          id={id}
          data-testid={`${id}-input`}
          value={value}
          name={name}
          onChange={onChange}
          data-hj-whitelist
        >
          {includeDefault && (
            <option value="">{getCommonLabelMessage(defaultKey, intl)}</option>
          )}
          {options.map((option, index) => renderOptions(option, index))}
        </StyledSelect>
      </SelectWrapper>
    </Wrapper>
  );
};

Select.defaultProps = {
  renderOptions: (option, index) => {
    return (
      <option key={`option-${index}`} value={option}>
        {option}
      </option>
    );
  },
};

export default Select;

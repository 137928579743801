import React from "react";
import { Button } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { useLogin } from "../../hooks/useLoggedIn";

export const LogInStatus = () => {
  const { loggedIn, loginUrl, loginLoading } = useLogin();

  if (loginLoading) return null;

  return (
    <div>
      {loggedIn ? (
        <FormattedMessage
          id="login-status.logged-in"
          defaultMessage="Logged in"
          description="label for login"
        />
      ) : (
        <Button variant="ghost" id="login" as="a" href={loginUrl}>
          <FormattedMessage
            id="login-status.go-to-login"
            defaultMessage="Login for prices"
            description="label for login link"
          />
        </Button>
      )}
    </div>
  );
};

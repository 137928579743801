import React, { FC, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import {
  LanguageProvider,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import { fetchTranslations } from "../utils/languageUtils";

type TranslationProps = {
  children: ReactNode;
};

const TranslationProvider: FC<TranslationProps> = ({ children }) => {
  return (
    <LanguageProvider shouldUpdateURL fetchTranslations={fetchTranslations}>
      <LanguageContext.Consumer>
        {({
          language,
          strings,
        }: {
          language: string;
          strings: { [k: string]: string };
        }) => {
          return (
            <IntlProvider locale={language} messages={strings}>
              {children}
            </IntlProvider>
          );
        }}
      </LanguageContext.Consumer>
    </LanguageProvider>
  );
};

export { TranslationProvider };

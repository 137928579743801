import { gql } from "@apollo/client";

const queryParams = `
    $erpSystem: String!
    $lang: String!
    $ballBore: String
    $constructionType: String
    $nominalInnerDiameter: String
    $connection: String
    $faceToFaceLength: String
    $housingMaterial: [String]
    $pressureRating: String
    $operation: String
    $norm: String
    $brands: [String]
    $pressure: String
    $temperature: String
`;

const queryMapping = `
      erpSystem: $erpSystem
      lang: $lang
      ballBore: $ballBore
      constructionType: $constructionType
      nominalInnerDiameter: $nominalInnerDiameter
      connection: $connection
      faceToFaceLength: $faceToFaceLength
      housingMaterial: $housingMaterial
      pressureRating: $pressureRating
      operation: $operation
      norm: $norm
      brands: $brands
      pressure: $pressure
      temperature: $temperature
`;

export const GET_VALVE_ATTRIBUTES = gql`
  query getValveAttributes(
    ${queryParams}
  ) {
    getValveAttributes(
      ${queryMapping}
    ) {
      attributes {
        brand {value}
        connection {value}
        constructionType {value}
        operation {value}
        norm {value}
        pressureRating {value}
        faceToFaceLength {value}
        housingMaterial {value}
        nominalInnerDiameter {value}
        ballBore {value}
        pressureTemperatureMeta
        
      }
    }
  }
`;

export const GET_VALVE_ITEMS = gql`
  query getValveItems(
    ${queryParams}
    $limit: Int
  ) {
    getValveItems(
      ${queryMapping}
      limit: $limit
    ) {
      valves {
        erpPartNumber
        partNumber
        brand
        price
        priceRaw
        itemLink
        description
        englishDescription
        imageUrl
        productOverviewUrl
      }
      hasMore
    }
  }
`;

export const GET_AVAILABLE_LANGUAGES = gql`
  query getAvailableLanguages($erpSystem: String!) {
    getAvailableLanguages(erpSystem: $erpSystem) {
      countryCode
      countryName
    }
  }
`;

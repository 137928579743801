import { gql } from "@apollo/client";

export const GET_SALES_ORGANIZATIONS = gql`
  query getSalesOrganizations {
    getSalesOrganizations {
      shop_url
      store_id
      country
    }
  }
`;

import { FormattedMessage } from "react-intl";
import React from "react";

export const getTagLabelMessage = (keyLabel) => {
  return {
    "tag-label.brand": (
      <FormattedMessage
        id="tag-label.brand"
        defaultMessage="Brand"
        description="Label for tag brand"
      />
    ),
    "tag-label.ballBore": (
      <FormattedMessage
        id="tag-label.ballBore"
        defaultMessage="Ball bore"
        description="Label for tag ball bore"
      />
    ),
    "tag-label.faceToFaceLength": (
      <FormattedMessage
        id="tag-label.faceToFaceLength"
        defaultMessage="Built-in length"
        description="Label for tag Built-in length"
      />
    ),
    "tag-label.connection": (
      <FormattedMessage
        id="tag-label.connection"
        defaultMessage="Connection"
        description="Label for tag Connection"
      />
    ),
    "tag-label.operation": (
      <FormattedMessage
        id="tag-label.operation"
        defaultMessage="Operation"
        description="Label for tag operation"
      />
    ),
    "tag-label.housingMaterial": (
      <FormattedMessage
        id="tag-label.housingMaterial"
        defaultMessage="Housing Material"
        description="Label for tag Housing Material"
      />
    ),
    "tag-label.norm": (
      <FormattedMessage
        id="tag-label.norm"
        defaultMessage="Norm"
        description="Label for tag Norm"
      />
    ),
    "tag-label.pressureRating": (
      <FormattedMessage
        id="tag-label.pressureRating"
        defaultMessage="Pressure rating"
        description="Label for tag Pressure rating"
      />
    ),
    "tag-label.nominalInnerDiameter": (
      <FormattedMessage
        id="tag-label.nominalInnerDiameter"
        defaultMessage="Nominal inner diameter"
        description="Label for tag Nominal inner diameter"
      />
    ),
    "tag-label.constructionType": (
      <FormattedMessage
        id="tag-label.constructionType"
        defaultMessage="Nominal construction type"
        description="Label for tag Nominal construction type"
      />
    ),
    "tag-label.pressure": (
      <FormattedMessage
        id="tag-label.pressure"
        defaultMessage="Pressure"
        description="Label for tag pressure"
      />
    ),
    "tag-label.temperature": (
      <FormattedMessage
        id="tag-label.temperature"
        defaultMessage="Temperature"
        description="Label for tag temperature"
      />
    ),
  }[keyLabel];
};

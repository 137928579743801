import React from "react";
import { FormattedMessage } from "react-intl";

export const PriceInformation = ({ price }: { price: string }) => {
  if (!price) {
    return (
      <span style={{ marginRight: "16px" }}>
        <FormattedMessage
          id="price-info.error"
          defaultMessage="Unable to fetch prices"
          description="Label for fetch prices error"
        />
      </span>
    );
  }
  return <span style={{ marginRight: "16px" }}>{price}</span>;
};

export const getCommonLabelMessage = (keyLabel, intl) => {
  return {
    "3-way": intl.formatMessage({
      id: "3-way",
      defaultMessage: "3-way",
      description: "label for 3-way",
    }),
    ALL: intl.formatMessage({
      id: "all",
      defaultMessage: "ALL",
      description: "label for all label in selection",
    }),
  }[keyLabel];
};
